import { Controller } from 'stimulus'
import { Liquid } from 'liquidjs';

/*
  The email from for job communication. This can be used multiple times, one
  for the sending email and saving email.

  The placeholder replacement code was upgraded in the
  components/textarea_placeholder_controller Stimulus controller.
  I've left this alone because it still works
*/
export default class extends Controller {
  liquidEngine     = new Liquid()
  stimulusTimeline = null

  static values = [
    'subject',
    'content',
    'comTypeField'
  ]

  static targets = [
    'subject',
    'content',
    'recipients', // Used?
    'comTypeField',
  ]

  initialize(){
    this.stimulusTimeline = document.querySelector('#stimulus-communications-timeline')
  }

  connect() {
    if(!placeholderJSON) console.error('No placeholderJSON on page')

    /* Used to identify which controller is used when logging */
    this.comType = this.comTypeFieldTarget.value
    this._debug('Initializing')

    this.element.recipientsLiquidEngine = this.liquidEngine

    this._eventOnFormSubmit()
  }

  // 'target' is the friendly version
  // 1) If there is a template place it into the friendly version
  //    This will contain the exposed placeholders
  startEditing(evt){
    let templateContent = this._getTemplate(evt.target)
    if(templateContent) evt.target.value = templateContent
  }

  // 'target' is the friendly version
  // 1) Set the template with the friendly version (with exposed placeholders)
  // 2) Render the content replacing the placeholders
  // 3) Using the rendered content place in siblings. Used in POSTing
  stopEditing(evt){
    this._setTemplate(evt.target, evt.target.value)
    evt.target.value = this._render(evt.target.value)
    this._setSiblingsValue(evt.target, evt.target.value)
  }

  // Select/Dropdown will populate with rendered subject/body
  useTemplate(evt){
    const templateId = evt.target.value
    let opts         = this._findTemplate(templateId)
    let subject      = opts.subject
    let body         = opts.body

    const wasUsingTemplate     = !!this.element.previousTemplateId
    const usingTemplate        = !!templateId.length
    const previousSubjectSaved = !!this.element.previousSubject?.length
    console.info('DEBUG:', {wasUsingTemplate, usingTemplate, previousSubjectSaved})

    // Ignored when changing between templates
    if(!(wasUsingTemplate && usingTemplate)) {
      if(usingTemplate) {
        console.info('Setting previousSubject', this.subjectTarget.value)
        this.element.previousSubject = this.subjectTarget.value
      } else if(previousSubjectSaved) {
        subject = this.element.previousSubject
        this.element.previousSubject = null
      }
    }


    this.subjectTarget.value = subject
    this.contentTarget.value = body

    // Avoid placing template into the input field, we don't want the placeholders
    // sent as the message
    this._setTemplate(this.subjectTarget, subject)
    this._setTemplate(this.contentTarget, body)

    this._setSiblingsValue(this.subjectTarget, this._render(subject))
    this._setSiblingsValue(this.contentTarget, this._render(body))

    this.element.previousTemplateId = templateId

    // Render after a delay, quick preview that there are placeholders
    setTimeout(()=>{
      this.subjectTarget.value = this._render(subject)
      this.contentTarget.value = this._render(body)
    }, 300)
  }

  // One success we'll want to add the message to the timeline
  _eventOnFormSubmit(){
    this.element.addEventListener('ajax:beforeSend', evt =>{
      if(this.hasSubjectTarget && this._emailType()) {
        this.subjectTarget.value = this.subjectTarget.value.trim()

        // Server side validates email subjects too
        if(!this.subjectTarget.value.length) {
          alert('You have not entered a subject for this email')
          evt.preventDefault()
        }
      }
    })

    this.element.addEventListener('ajax:success', _evt =>{

      this._removeRecipients()

      o2v.flashMessagesReset()
      o2v.notice('Success, refreshing page...')

      // Easier to refresh to show the new communication
      window.location.href = location.href
    })

    this.element.addEventListener('ajax:error', evt =>{
      o2v.flashMessagesReset()
      evt.detail[0].messages.forEach(msg => o2v.warning(msg) )
    })
  }

  /* Remove recipients to avoid accidental double sending */
  _removeRecipients() {
    const controller = this.element.querySelector('section.o2v-tagify').stimulusController

    if(controller) controller.clearValues()
  }

  _setTemplate(el, template){
    this._siblingInput(el).setAttribute('data-template', template)
  }

  _getTemplate(el){
    return this._siblingInput(el).getAttribute('data-template')
  }

  _setSiblingsValue(el, value){
    this._siblingInput(el).value = value
  }

  _siblingInput(el){
    const parentEl = el.closest('section.job-email')
    const sibClass = el.getAttribute('data-sibling-class')
    return parentEl.querySelector(sibClass+' > input')
  }

  _findTemplate(TemplateId){
    let opts = emailTemplatesJSON[TemplateId]
    return (opts || {subject: '', body: ''})
  }

  _render(template){
    // console.info('this.element.previousSubjectValue', template, this.element.previousSubjectValue)
    // this.element.previousSubjectValue = this.subjectTarget.value

    return this.liquidEngine.parseAndRenderSync(template, placeholderJSON)
  }

  // Delegate adding to the timeline to Stimulus controller that manages it
  _addTimelineMessage(job){
    let message = []
    if(job.subject) message.push(job.subject)
    if(job.content) message.push(job.content)
  }

  _emailType(){
    return this.comType == 'EMAIL'
  }

  _debug() {
    console.debug('[D][CommunicationFormController]',  this.comType, ...arguments)
  }
}