import { Controller } from 'stimulus'

// Toggles the parent's children toggles on/off based on the parents state.
// - This could be expanded on if more advance toggles are required.
// - It only inverts the the parents toggle for the child toggle state
//
export default class extends Controller {
  static targets = [
    'flag'
  ]

  initialize(){
    this.#logDebug('Toggle input children')

    this.flagTargets.forEach(input => {
      this.#toggleInputs(input)
    })
  }

  flagChange(evt){
    const input   = evt.target
    this.#toggleInputs(input)
  }

  #toggleInputs(input){
    const enabled = input.checked

    const wrapperUl = input.closest('li').querySelector('.flag-options_dependents')

    // Not a parent
    if(!wrapperUl) return

    const children = wrapperUl.querySelectorAll('.flag-options_dependents input')

    wrapperUl.classList.toggle('flags-disabled', !enabled)

    Array.from(children).forEach(el => {
      if(enabled) {
        el.removeAttribute('disabled')
      } else {
        el.setAttribute('disabled', 'disabled')
        el.checked = false
      }
    })
  }

  #logDebug() {
    console.debug('[D][FormFlagOptions]', ...arguments)
  }
}