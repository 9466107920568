import { Controller } from 'stimulus';
import {highlightHint} from '../utils/html_helpers.js'

/*
  Handles state selection for AU

  The file name could be changed to reflect the single responsibility. The
  controller originally handled checklist and tag fields but was slimmed down.

  Set specific user based on state. The timezone is set using this state field.
  There needs to be user IDs in the state option and an associated ID in the
  delegator select for this to work.
*/
export default class extends Controller {
  newRecord

  static targets = [
    'selectJobType',
    'checklistJson',
    'checklistArea',
    'checklistItemTemplate',
    'jobTags',
    'checklistItem',
    'newJobContactFields',
    'jobContactId',
    'jobContactCreateButton'
  ]
  static values = {
    creatingContact: Boolean
  }

  connect(){
    // TODO: convert to a Stimulus Value?
    this.newRecord =  this.element.dataset.newRecord == 'true'

    if(this.creatingContactValue) {
      this.showNewJobContact()
    } else {
      this.hideNewJobContact()
    }

    if (!this.hasJobTypeTarget) return
    this._saveSelected()
    this._initOnNew()
    this._toggleChecklist()
  }

  stateSelect(evt){
    this.#markDirty(evt.target)
    const selEl = this.#getSelectOption(evt.target)

    if(!this.#manuallyChanged(this.#timezoneElement)) {
      this.#updateTimezone(selEl.dataset.timezone)
    }

    // Only update if the job is NEW and NOT DIRTY
    if(this.newRecord && !this.#manuallyChanged(this.#jobDelegatorIdElement)) {
      this.#updateDelegatorId(selEl.dataset.delegatorId)
    }
  }

  showNewJobContact(evt){
    if(!this.hasNewJobContactFieldsTarget) {
      return
    }
    // show new contact form
    this.newJobContactFieldsTarget.classList.remove('hidden')
    this.newJobContactFieldsTarget.querySelectorAll('input, select, textarea').forEach(function(formEl) {
      formEl.removeAttribute('disabled')
    })
    // hide job contact select
    this.jobContactIdTarget.classList.add('hidden')
    this.jobContactIdTarget.querySelector('select').setAttribute('disabled', 'disabled')
    this.jobContactCreateButtonTarget.classList.add('hidden')
  }

  hideNewJobContact(evt){
    if(!this.hasNewJobContactFieldsTarget) {
      return
    }
    // hide new contact form
    this.newJobContactFieldsTarget.classList.add('hidden')
    this.newJobContactFieldsTarget.querySelectorAll('input, select, textarea').forEach(function(formEl) {
      formEl.setAttribute('disabled', 'disabled')
    })
    // show job contact select
    this.jobContactIdTarget.classList.remove('hidden')
    this.jobContactIdTarget.querySelector('select').removeAttribute('disabled')
    this.jobContactCreateButtonTarget.classList.remove('hidden')
  }

  // Disabled for now. State will choose delegator and timezone
  selectDelegator(evt){
    // this.#markDirty(evt.target)
    // const selEl = this.#getSelectOption(evt.target)

    // this.#updateTimezone(selEl.dataset.timezone)
  }

  // Not used
  #setTimezoneByDelegator(){
    const timezone = this.#getSelectOption(this.#jobDelegatorIdElement)?.dataset.timezone
    if(timezone) this.#updateTimezone(timezone)
  }

  updateChecklist(evt){
    let jobType = this._findJobType(parseInt(evt.target.value))
    this._clearChecklist()
    this._addCheckList(jobType)
    this._updateTagifyWhitelist()
  }

  /* No checklist exists on new page, get the job type and generate from JSON */
  _initOnNew(){
    let jobType = this._findJobType(parseInt(this.selectJobTypeTarget.value))

    if(jobType.checklist.length && this._checklistEmpty()) {
      this._addCheckList(jobType)
    }
  }

  // Get the tagify element and update it's whitelist
  _updateTagifyWhitelist(){
    if(!this.hasJobTypeTarget) return

    let jobType = this._findJobType(parseInt(this.selectJobTypeTarget.value))
    this.jobTagsTarget.tagify.settings.whitelist = jobType.tags
  }

  _findJobType(jobTypeId){
    return jobTypeJson.filter((item)=>{ return item.job_type_id == jobTypeId })[0]
  }

  _addCheckList(checklistAry){
    if(checklistAry.checklist.length) {
      checklistAry.checklist.forEach((item)=>{
        this._appendChild(this._checklistFragment(item))
      })
    }

    this._toggleChecklist()
  }

  _toggleChecklist(){

    const jobType = this._findJobType(parseInt(this.selectJobTypeTarget.value))

    if(jobType.checklist.length)
      this.element.classList.remove('-no-checklist')
    else
      this.element.classList.add('-no-checklist')
  }

  // Create from template generated by SimpleForm (O2vCheckBoxesInput)
  _checklistFragment(obj){
    const idx    = this._itemCount()
    const objId = obj.id || `_new_${idx}`
    let str      = this.checklistItemTemplateTarget.innerHTML.trim()
    let el       = document.createElement('li')

    el.innerHTML = str.replace(/%IDX%/g, idx)

    const elId = `job_checklist_id_${objId}`

    let checkboxEl = el.querySelector('label.checkbox input')

    checkboxEl.setAttribute('id', elId)
    checkboxEl.setAttribute('value', objId)

    this._updateRecordEl(el, checkboxEl, objId)

    el.querySelector('label.checkbox').setAttribute('for', elId)

    el.querySelector('.title').innerText = obj.title

    el.querySelector('input.checklist_item_id').value = objId

    return el
  }

  /* Update or remove the element using the saved state.
  /  The saved state keeps track of the checklist item ID and the
  /  checklist item job ID (Join table). the check state is then true|false
  */
  _updateRecordEl(el, checkboxEl, objId){
    let inputIdEl = el.querySelector('.checklist_job_id')

    if(objId in this.savedSelected) {
      const {jobId, checked} = this.savedSelected[objId]
      if(checked) checkboxEl.setAttribute('checked', true)
      inputIdEl.value = jobId
    } else {
      inputIdEl.remove()
    }
  }

  /* Save the selected state for later. Change the state
  /  if user reselects job type
  */
  _saveSelected(){
    this.checklistItemTargets.forEach((input)=>{
      const jobId  = parseInt(input.getAttribute('data-checklist-item-job-id'))
      const itemId = parseInt(input.getAttribute('data-checklist-item-id'))
      this.savedSelected[itemId] = {jobId: jobId, checked: input.checked}
    })
  }

  _appendChild(child){
    this._checklistRoot().appendChild(child)
  }

  _checklistRoot(){
    return this.checklistAreaTarget.querySelector('ul')
  }

  _itemCount(){
    const elements = this.checklistAreaTarget.querySelectorAll('ul > li')
    return elements ? elements.length : 0
  }

  _clearChecklist(){
    this._checklistRoot().innerHTML = ''
  }

  _checklistEmpty(){
    return !this.element.querySelectorAll('ul li').length
  }

  #getSelectOption(el){
    return el.options[el.selectedIndex]
  }

  #updateTimezone(tz){
    if(!tz) return
    this.#updateField('timezone', tz)
  }

  #updateDelegatorId(dId){
    if(!dId) return
    dId = dId.toString()

    if(this.#currentDelegatorIds.includes(dId)) {
      this.#updateField('delegator_user_id', dId)
    } else {
      this.#warn(`No delegator ID ${dId} present in current list`)
    }
  }

  #updateField(name, value){
    const el =  this.#getElementByCol(name)

    if(!el) {
      this.#warn(`No element for ${name} for updating, ignored`)
      return
    }

    el.value = value
    highlightHint(el)
  }

  get #timezoneElement(){
    return  this.#getElementByCol('timezone')
  }

  get #jobDelegatorIdElement(){
    return  this.#getElementByCol('delegator_user_id')
  }

  #getElementByCol(name){
    const elements = document.getElementsByName(`job[${name}]`)

    if(elements.length != 1)
      this.#warn(`Wrong number of elements returned for ${name} ID`)

    return elements[0]
  }

  get #currentDelegatorIds(){
    const elements = Array.from(this.#jobDelegatorIdElement.options)
    return elements.map(el => el.value).filter(str => str != '')
  }

  // Avoid updating fields the user as set themselves. #manuallyChanged is
  // the accompanying method
  #markDirty(el){
    if(el) el.dataset.dirty = true
  }

  // or could be named #isDirty
  #manuallyChanged(el){
    return el && !!el.dataset.dirty
  }

  #log()  { console.debug('[D][JobFormController]', ...arguments) }
  #warn() { console.warn('[W][JobFormController]', ...arguments) }
}